@import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css";
@import "./styles/paletas";
@import "./styles/fonts";
@import url('./modal.scss');

*,
*:before,
*:after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
	border: 0;
}

body { 
	background-image: url(assets/bg-body.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.font-outfit {
	font-family: 'Outfit', sans-serif;
}

.container__primary {
	max-width: 1344px;
	margin: 0 auto;

	@media only screen and (max-width: 1407px) and (min-width: 1216px) {
		max-width: 85%;
	}

	@media only screen and (max-width: 1215px) {
		max-width: 90%;
	}

	@media only screen and (max-width: 768px) {
		max-width: 100%;
	}
}

.header {
	// height: 260px;
	background-position: 0 35%;
	background-size: cover;

	@media only screen and (max-width: 600px) {
		height: 234px;
	}
	@media only screen and (min-width: 600px) and (max-width: 1440px) {
		height: 350px;
	}

	@media only screen and (min-width: 1440px) {
		height: 410px;
	}
}

.two_columns {
	display: grid;
	grid-template-columns: 50% 50%;
	height: 100%;

	@media only screen and (width< 1024px) {
		grid-template-columns: 100%;
	}
}

.profile {
	margin: 0 auto;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -80px;

	height: 170px;
	width: 170px;
	object-fit: cover;

}

#container__seciton {
	column-count: 2;
	gap: 25px;

	@media only screen and (width< 1024px) {
		column-count: 1;
		margin-bottom: 0 !important;
	}
}

.is-hover-btn {
	&:hover {
		filter: brightness(.9);
	}
}

.contac-links-container {
	@media only screen and (max-width: 1023px) and (min-width: 769px) {

		gap: 10px;

		.contac-links {
			gap: 10px !important;
		}
	}
}

.content__icon {
	cursor: pointer !important;
	min-width: 70px !important;

}

.btn-link {
	cursor: pointer;
}

li::marker {
	color: var(--spectra-yeloow-600);
}

// .s-hover-btn-secondary {
// 	&:hover {
// 		border: 5px solid var(--red-stop-700) !important;
// 	}
// }

.formation-mobile {
	@media only screen and (max-width: 600px) {
		flex-direction: column !important;

		img {
			display: none;
		}

	}
}

.service-slide {
	height: 280px;

	@media only screen and (max-width: 600px) {
		height: auto;
	}
}

.copy-btn {
	cursor: pointer;
}


// Alert CSS

/* The alert message box */
.alert {
	color: #fff;
	background-color: #04AA6D;
	margin-bottom: 15px;
	padding: 20px;
	position: fixed;
	width: 100%;
	bottom: -15px;
	left: 0;
	z-index: 100;
	opacity: 0;
	transition: ease-in-out 0.5s;
}

/* The close button */
.closebtn {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
	color: black;
}